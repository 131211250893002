import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'HistoryComponent',
  data: function data() {
    return {
      historys: [],
      activeValue: 'dashboard',
      contextMenuVisible: false,
      left: 0,
      top: 0,
      isCollapse: false,
      isMobile: false,
      rightActive: ''
    };
  },
  created: function created() {
    var _this = this;

    this.$bus.on('mobile', function (isMobile) {
      _this.isMobile = isMobile;
    });
    this.$bus.on('collapse', function (isCollapse) {
      _this.isCollapse = isCollapse;
    });
    var initHistorys = [{
      name: 'dashboard',
      meta: {
        title: '仪表盘'
      }
    }];
    this.historys = JSON.parse(sessionStorage.getItem('historys')) || initHistorys;
    this.setTab(this.$route);
  },
  beforeDestroy: function beforeDestroy() {
    this.$bus.off('collapse');
    this.$bus.off('mobile');
  },
  methods: {
    openContextMenu: function openContextMenu(e) {
      if (this.historys.length == 1 && this.$route.name == 'dashboard') {
        return false;
      }

      if (e.srcElement.id) {
        this.contextMenuVisible = true;
        var width;

        if (this.isCollapse) {
          width = 54;
        } else {
          width = 220;
        }

        if (this.isMobile) {
          width = 0;
        }

        this.left = e.clientX - width;
        this.top = e.clientY + 10;
        this.rightActive = e.srcElement.id.split('-')[1];
      }
    },
    closeAll: function closeAll() {
      this.historys = [{
        name: 'dashboard',
        meta: {
          title: '仪表盘'
        }
      }];
      this.$router.push({
        name: 'dashboard'
      });
      this.contextMenuVisible = false;
      sessionStorage.setItem('historys', JSON.stringify(this.historys));
    },
    closeLeft: function closeLeft() {
      var _this2 = this;

      var right;
      var rightIndex = this.historys.findIndex(function (item) {
        if (item.name == _this2.rightActive) {
          right = item;
        }

        return item.name == _this2.rightActive;
      });
      var activeIndex = this.historys.findIndex(function (item) {
        return item.name == _this2.activeValue;
      });
      this.historys.splice(0, rightIndex);

      if (rightIndex > activeIndex) {
        this.$router.push(right);
      }

      sessionStorage.setItem('historys', JSON.stringify(this.historys));
    },
    closeRight: function closeRight() {
      var _this3 = this;

      var right;
      var leftIndex = this.historys.findIndex(function (item) {
        if (item.name == _this3.rightActive) {
          right = item;
        }

        return item.name == _this3.rightActive;
      });
      var activeIndex = this.historys.findIndex(function (item) {
        return item.name == _this3.activeValue;
      });
      this.historys.splice(leftIndex + 1, this.historys.length);

      if (leftIndex < activeIndex) {
        this.$router.push(right);
      }

      sessionStorage.setItem('historys', JSON.stringify(this.historys));
    },
    closeOther: function closeOther() {
      var _this4 = this;

      var right;
      this.historys = this.historys.filter(function (item) {
        if (item.name == _this4.rightActive) {
          right = item;
        }

        return item.name == _this4.rightActive;
      });
      this.$router.push(right);
      sessionStorage.setItem('historys', JSON.stringify(this.historys));
    },
    setTab: function setTab(route) {
      if (!this.historys.some(function (item) {
        return item.name == route.name;
      })) {
        var obj = {};
        obj.name = route.name;
        obj.meta = route.meta;
        obj.query = route.query;
        obj.params = route.params;
        this.historys.push(obj);
      }

      this.activeValue = this.$route.name;
    },
    changeTab: function changeTab(component) {
      var tab = component.$attrs.tab;
      this.$router.push({
        name: tab.name,
        query: tab.query,
        params: tab.params
      });
    },
    removeTab: function removeTab(tab) {
      var index = this.historys.findIndex(function (item) {
        return item.name == tab;
      });

      if (this.$route.name == tab) {
        if (this.historys.length == 1) {
          this.$router.push({
            name: 'dashboard'
          });
        } else {
          if (index < this.historys.length - 1) {
            this.$router.push({
              name: this.historys[index + 1].name,
              query: this.historys[index + 1].query,
              params: this.historys[index + 1].params
            });
          } else {
            this.$router.push({
              name: this.historys[index - 1].name,
              query: this.historys[index - 1].query,
              params: this.historys[index - 1].params
            });
          }
        }
      }

      this.historys.splice(index, 1);
    }
  },
  watch: {
    contextMenuVisible: function contextMenuVisible() {
      var _this5 = this;

      if (this.contextMenuVisible) {
        document.body.addEventListener('click', function () {
          _this5.contextMenuVisible = false;
        });
      } else {
        document.body.removeEventListener('click', function () {
          _this5.contextMenuVisible = false;
        });
      }
    },
    $route: function $route(to) {
      this.historys = this.historys.filter(function (item) {
        return !item.meta.hidden;
      });
      this.setTab(to);
      sessionStorage.setItem('historys', JSON.stringify(this.historys));
    }
  }
};